<template>
    <div>
        <div v-if="cast && castandcrew" >
            <div class="cast-an-crew">{{$t('Cast')}}: </div> 
            <div class="cast-an-crew-value">{{cast}}</div>
        </div>

        <div v-if="crew && castandcrew">
            <div class="cast-an-crew">{{$t('Crew')}}: </div> 
            <div class="cast-an-crew-value">{{crew}}</div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props:["castCrew"],
    data() {
        return {
            cast: '',
            crew: '',
            castandcrew: null,
            appconfig: null
        }
    },
    computed: {
    ...mapGetters([
      "appConfig",
    ]),
  },
  mounted () {
    // this.appconfig = JSON.parse(localStorage.getItem("appConfig"));
    this.castandcrew = this.appConfig.featureEnabled.castandcrew;
    
    // Process cast data
    this.castCrew.cast && this.castCrew.cast.forEach((item) => {
        this.cast += item.name + ", "; 
    });
    this.cast = this.cast.slice(0, -2);

    // Process crew data
    this.castCrew.crew && this.castCrew.crew.forEach((item) => {
        const translatedRole = this.$t(`roles.${item.role[0]}`);
        this.crew += item.name + (translatedRole ? ` (${translatedRole}),` : '') + " "; 
    });
    this.crew = this.crew.slice(0, -2);

}


}
</script>
<style lang="scss" scoped>
    @import "@/sass/_variables.scss";
    .cast-an-crew {
        color: $clr-backnav-arrw-txt;
        display: inline;
        font-weight: bold;
    }
    .cast-an-crew-value {
       color: #fff;
       display: inline;
       font-size: 0.75rem;
       line-height: 1.4rem;
    }
    

</style>